<script lang="ts" setup>
const { t } = useT();

const props = defineProps<{
	itemSection: number;
}>();

const { pageData, lastGames } = useHomePage();
const isGuest = useIsGuest();
const { select } = useGamesState();

const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);
const isPrerenderedComponent = (count: number) => count <= 2;
</script>
<template>
	<template v-for="(game, index) in games" :key="index">
		<div v-if="game?.slug === 'last' && lastGames?.length">
			<LazyOHomeGames
				v-if="!isGuest && lastGames?.length"
				:games="lastGames"
				:title="t('Recently Played')"
				icon="home/recent"
				category="favorites"
				:rows-per-slide="1"
			/>
		</div>
		<OLazyComponent
			v-else-if="
				pageData?.games[camelCase(game?.slug ?? '')]?.length && pageData?.games[camelCase(game?.slug ?? '')]?.length > 0
			"
			:id="`home-game-${game?.slug}`"
			:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
		>
			<LazyOHomeGames
				:games="select(pageData?.games[camelCase(game?.slug ?? '')])"
				:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
				:icon="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.logo || ''"
				:category="game?.slug"
				:rows-per-slide="game?.row_count"
				:type="game?.type_component"
				isAdminIcon
			/>
		</OLazyComponent>
	</template>
</template>
